import React from "react";
import {Thumbnail} from "@shopify/polaris";


export const Placeholder = ({children, showBorder = false}) => {
    return (
        <div style={{
            borderInlineStart: showBorder ? '2px solid var(--p-color-border)' : 'none',
            padding: '0em 2em',
            width: 'auto',
        }}>
            {children}
        </div>
    );
};

export function productThumbnail(url, title) {
    return (
        <Thumbnail
            source={url}
            alt={title}
        />
    );
}

export const handleMouseMoveZoom = (e) => {
    const x = e.nativeEvent.offsetX;
    const y = e.nativeEvent.offsetY;
    const zoom = 2;
    e.target.style.transform = `scale(${zoom})`;
    e.target.style.transformOrigin = `${x}px ${y}px`;
    e.target.style.zIndex = 1000;
}

export const handleMouseOutZoom = (e) => {
    e.target.style.transform = `scale(1)`;
    e.target.style.zIndex = 0;

}