import React, {Fragment, useCallback, useContext, useState} from 'react';
import {useEffect} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import Panel from 'rsuite/Panel';
import {Table, Button, Input, InputGroup, ButtonToolbar, Modal, Form, IconButton} from 'rsuite';
import {adminResetQuota, adminUpdateShopData, getAdminData} from "../../servies/admin.services";
import ToasterMessage from "../../components/common/toaster_message";
import {CustomModal} from "../common/common_modals";
import {getAuthSession} from "../../servies/common.services";
import CheckRoundIcon from '@rsuite/icons/CheckRound';
import WarningRoundIcon from '@rsuite/icons/WarningRound';


const { Column, HeaderCell, Cell } = Table;

export const AdminDashboard = () => {
    const {search} = useLocation();
    const [tableData, setTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [paramsData, setParamsData] = useState('');
    const [isResetQuota, setIsResetQuota] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [isUpdatingFirstName, setIsUpdatingFirstName] = useState(false);
    const [isUpdating, setIsUpdating] = React.useState(false);
    const [authData, setAuthData] = useState({});
    const navigate = useNavigate();
    const [formValue, setFormValue] = React.useState({first_name: '', predictions_left: 0});
    const [searchTerm, setSearchTerm] = useState('');

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);

    const handelResetQuota = (shop) => {

        const params = `${paramsData}&is_admin=true&shop=${shop}`;
        setIsResetQuota(true);
        adminResetQuota(params)
            .then((res) => {
                const data = res.data;
                if (data === null) {
                    ToasterMessage.error(res.error);
                    return;
                }
                ToasterMessage.success(`Reset Quota Successfully for ${shop}`);

            })
            .catch((e) => {
                const errorCode = e.code;
                const errorMessage = e.message;
                ToasterMessage.error(errorMessage);
                setIsResetQuota(false);
                console.log(errorCode, errorMessage);
            })
            .finally(() => {
                console.log('finally');
                setIsResetQuota(false);
            })
    }

    const handelUpdateShopData = () => {
        setIsUpdating(true);
        const inputData = {
            'shop_data':{
                'first_name':formValue.first_name,
                'predictions_left':parseInt(formValue.predictions_left),
            }
        };

        const params = `${paramsData}&shop=${selectedRow.shop_url}`;

        adminUpdateShopData(params,inputData)
            .then((res) => {
                const data = res.data;
                if (data === null) {
                    ToasterMessage.error(res.error);
                    return;
                }
                console.log('shopdata',data)

                let row = tableData.find((row)=>row.shop_url === selectedRow.shop_url);
                row.first_name = data.shop_data.first_name;
                row.predictions_left = data.shop_data.predictions_left;

                console.log('row after',row,tableData)
                setTableData([...tableData]);

                ToasterMessage.success(`Updated First Name Successfully for ${selectedRow.shop_url}`);

            })
            .catch((e) => {
                const errorCode = e.code;
                const errorMessage = e.message;
                ToasterMessage.error(errorMessage);
                setIsUpdating(false);
                console.log(errorCode, errorMessage);
            })
            .finally(() => {
                console.log('finally');
                setIsUpdating(false);
                setOpen(false);
            })
    }

    useEffect(() => {
        const authSession = getAuthSession();
        if (authSession === null) {
            navigate('/admin/login');
            return;
        }

        const token = authSession.token;
        setAuthData(authSession);

        const params = `token=${token}&is_admin=true`;
        setParamsData(params);
        setIsLoading(true);

        getAdminData(params)
            .then((res) => {
                console.log(res);

                const data = res.data;
                if (data === null) {
                    ToasterMessage.error(res.error);
                    return;
                }
                setTableData(data);

            })
            .catch((e) => {
                const errorCode = e.code;
                const errorMessage = e.message;
                ToasterMessage.error(errorMessage);
                setIsLoading(false);
                console.log(errorCode, errorMessage);
            })
            .finally(() => {
                console.log('finally');
                setIsLoading(false);
            })

    },[])


    const updateShopDataModalRender = ()=>{
        return <CustomModal
            open={open}
            setOpen={setOpen}
            title={'Update Shop Data'}
            leftButtonText={'Update'}
            rightButtonText={'Cancel'}
            leftIsLoading={isUpdating}
            rightOnClick={()=>setOpen(false)}
            leftOnClick={handelUpdateShopData}
        >
            <Form fluid onChange={setFormValue} formValue={formValue}>
                <Form.Group controlId="first_name">
                    <Form.ControlLabel>First Name</Form.ControlLabel>
                    <Form.Control name="first_name" />
                </Form.Group>
                <Form.Group controlId="predictions_left">
                    <Form.ControlLabel>Predictions Left</Form.ControlLabel>
                    <Form.Control name="predictions_left" type={'number'}/>
                </Form.Group>
            </Form>
        </CustomModal>
    }


    // Filter the data based on the search term
    const filteredData = tableData.filter((item) =>
        Object.values(item).some(
            (val) =>
                val &&
                val.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    return (
        <Fragment>
            {updateShopDataModalRender()}
            <Panel>
                <Input
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(value) => setSearchTerm(value)}
                    style={{ marginBottom: 10, width: 200 }}
                />
                <Table
                    // height={500}
                    autoHeight={true}
                    // width={1310}
                    data={filteredData}
                    onRowClick={rowData => {
                        setSelectedRow(rowData);
                        setFormValue({first_name: rowData.first_name, predictions_left: rowData.predictions_left,})
                    }}
                    loading={isLoading || isUpdatingFirstName}

                >
                    <Column width={250} resizable>
                        <HeaderCell>Shop URL</HeaderCell>

                        <Cell style={{ padding: '6px' }}>
                            {rowData => (
                                <Link to={{pathname:'/', search: `?${paramsData}&shop=${rowData.shop_url}`,}} target={'_blank'}>
                                    {rowData.shop_url}
                                </Link>
                            )}
                        </Cell>
                    </Column>

                    <Column width={230} resizable>
                        <HeaderCell>Domain Website URL</HeaderCell>

                        <Cell style={{ padding: '6px' }}>
                            {rowData => (
                                <a href={`https://${rowData.domain}`} target={'_blank'}>
                                    {rowData.domain}
                                </a>
                            )}
                        </Cell>
                    </Column>

                    <Column width={150} resizable>
                        <HeaderCell>Store Name</HeaderCell>
                        <Cell dataKey="store_name" />
                    </Column>

                    <Column width={150} resizable>
                        <HeaderCell>First Name</HeaderCell>
                        <Cell
                            dataKey='first_name'
                            onClick={() => handleOpen()}
                        />
                    </Column>

                    <Column width={70} resizable>
                        <HeaderCell>Installed</HeaderCell>
                        <Cell style={{ padding: '6px' }}>
                            {rowData => (
                                rowData.is_installed === 1 ?
                                    <CheckRoundIcon color={'green'}  style={{ fontSize: 'x-large', marginRight: 10 }}/>
                                    :
                                    <WarningRoundIcon color={'red'} style={{ fontSize: 'x-large', marginRight: 10 }}/>
                            )}
                        </Cell>
                    </Column>

                    <Column width={120} resizable>
                        <HeaderCell>Total Generations</HeaderCell>
                        <Cell dataKey='total_predictions'/>
                    </Column>

                    <Column width={150} resizable>
                        <HeaderCell>Predictions Left</HeaderCell>
                        <Cell
                            dataKey='predictions_left'
                            onClick={() => handleOpen()}
                        />
                    </Column>

                    <Column width={250} resizable fullText={true}>
                        <HeaderCell>Date of Install</HeaderCell>
                        <Cell dataKey="install_date" />
                    </Column>

                    {/*<Column width={80} resizable>*/}
                    {/*    <HeaderCell>Free Trial Days</HeaderCell>*/}
                    {/*    <Cell dataKey="free_trail_days" />*/}
                    {/*</Column>*/}

                    <Column width={80} resizable>
                        <HeaderCell># of monthly Sales</HeaderCell>
                        <Cell dataKey="monthly_sale" />
                    </Column>

                    <Column width={100} resizable>
                        <HeaderCell>Reset Quota</HeaderCell>

                        <Cell style={{ padding: '6px' }}>
                            {rowData => (
                                <Button appearance="link" onClick={() => handelResetQuota(rowData.shop_url)} disabled={isResetQuota}>
                                    Reset
                                </Button>
                            )}
                        </Cell>
                    </Column>
                    <Column width={100} resizable>
                        <HeaderCell>Edit</HeaderCell>

                        <Cell style={{ padding: '6px' }}>
                            {rowData => (
                                <Button appearance="link" onClick={handleOpen} >
                                    Edit
                                </Button>
                            )}
                        </Cell>
                    </Column>
                </Table>

            </Panel>
        </Fragment>

    )


}