import {getAPIConfig, getAPIDeletePlanConfig} from "../app.config";
import axios from "axios";

export const getPaymentPlan = async (params,isAll=false) => {
    const verificationKey = btoa(params).toString('base64');
    const envKey = 'REACT_APP_API_PAYMENT_PLAN_PATH';
    const axiosConfig = getAPIConfig(envKey, verificationKey, 'GET',null,`is_all=${isAll}`);
    let data = {data: [], error: ''};

    const response = await axios(axiosConfig);
    const responseData = await response.data;


    if (responseData.status) {
        data.data = responseData.data;
        data.error = '';
    } else {
        data.data = null;
        data.error = responseData.message;
    }

    return data;
}

export const updatePaymentPlan = async (params,inputData) => {
    const verificationKey = btoa(params).toString('base64');
    const envKey = 'REACT_APP_API_UPDATE_PAYMENT_PLAN_PATH';
    const axiosConfig = getAPIConfig(envKey, verificationKey, 'PATCH',inputData);
    let data = {data: [], error: ''};

    const response = await axios(axiosConfig);
    const responseData = await response.data;

    if (responseData.status) {
        data.data = responseData.data;
        data.error = '';
    } else {
        data.data = null;
        data.error = responseData.message;
    }

    return data;
}

export const createPaymentPlan = async (params,inputData) => {
    const verificationKey = btoa(params).toString('base64');
    const envKey = 'REACT_APP_API_CREATE_PAYMENT_PLAN_PATH';
    const axiosConfig = getAPIConfig(envKey, verificationKey, 'POST',inputData);
    let data = {data: [], error: ''};

    const response = await axios(axiosConfig);
    const responseData = await response.data;

    if (responseData.status) {
        data.data = responseData.data;
        data.error = '';
    } else {
        data.data = null;
        data.error = responseData.message;
    }

    return data;
}

export const deletePaymentPlan = async (params,id) => {
    const verificationKey = btoa(params).toString('base64');
    const axiosConfig = getAPIDeletePlanConfig(verificationKey, id);
    let data = {data: [], error: ''};

    const response = await axios(axiosConfig);
    const responseData = await response.data;

    if (responseData.status) {
        data.data = responseData.data;
        data.error = '';
    } else {
        data.data = null;
        data.error = responseData.message;
    }

    return data;
}

export const changeDefaultActivePaymentPlan = async (params,inputData) => {
    const verificationKey = btoa(params).toString('base64');
    const envKey = 'REACT_APP_API_CHANGE_DEFAULT_ACTIVE_PAYMENT_PLAN_PATH';
    const axiosConfig = getAPIConfig(envKey, verificationKey, 'POST',inputData);
    let data = {data: [], error: ''};

    const response = await axios(axiosConfig);
    const responseData = await response.data;

    if (responseData.status) {
        data.data = responseData.data;
        data.error = '';
    } else {
        data.data = null;
        data.error = responseData.message;
    }

    return data;
}