export const setAuthSession = (data) => {
    const encryptedData = btoa(JSON.stringify(data));
    localStorage.setItem('auth',encryptedData);
}

export const getAuthSession = () => {
    const encryptedData = localStorage.getItem('auth');
    if (encryptedData === null) {
        return null;
    }
    return JSON.parse(atob(encryptedData));
}

export const removeAuthSession = () => {
    localStorage.removeItem('auth');
}
