import React from "react";


const Error400 = () => {
  return (
    <div className="error400">
      <h2>Not Authorized</h2>
    </div>
  );
}

export default Error400;