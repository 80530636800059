import {Button, Modal, toaster} from "rsuite";
import React, {useState} from "react";

export const CustomModal = ({
                                open,
                                setOpen,
                                title = '',
                                children,
                                leftButtonText = '',
                                rightButtonText = '',
                                leftButtonIcon = null,
                                rightButtonIcon = null,
                                leftIsLoading = false,
                                rightIsLoading = false,
                                leftOnClick = null,
                                rightOnClick = null
                            }) => {

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
        >
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            <Modal.Footer>
                {leftButtonText === '' || leftButtonText === null ? null :
                    <Button
                        onClick={leftOnClick !== null ? leftOnClick : () => setOpen(false)}
                        appearance="primary"
                        loading={leftIsLoading}
                        startIcon={leftButtonIcon}
                    >
                        {leftButtonText}
                    </Button>
                }
                {rightButtonText === '' || rightButtonText === null ? null :
                    <Button
                    onClick={rightOnClick !== null ? rightOnClick : () => setOpen(false)}
                    appearance="subtle"
                    loading={rightIsLoading}
                    startIcon={rightButtonIcon}
                >
                    {rightButtonText === '' ? 'Cancel' : rightButtonText}
                </Button>
                }

            </Modal.Footer>
        </Modal>
    )
}
