import {CustomModal} from "../common/common_modals";
import React from "react";

export const TipModal = ({title,open,setOpen}) => {


    return <CustomModal
        title='Which image work best for AI Video'
        open={open}
        setOpen={setOpen}
        leftButtonText={'OK'}
        leftOnClick={() => setOpen(false)}
    >
        <div
            style={{background: '#fbfbfb'}}
        >
            <figure>
                <img src="images/does_donts/image-1-dont.png"
                     alt="Avoid pairs of images where the background is different between the start and end image"
                     style={{"width": "100%"}}
                />
                <figcaption align='center'>A
                    void pairs of images where the background is different between the start and end image
                </figcaption>
            </figure>
            <figure>
                <img src="images/does_donts/image-2-dont.png"
                     alt="Avoid pairs of images that are very different from each other"
                     style={{"width": "100%"}}
                />
                <figcaption align='center'>Avoid pairs of images that are very different from each other
                </figcaption>
            </figure>
            <figure>
                <img src="images/does_donts/image-1-does.png"
                     alt="Choose 2 images where there is an obvious transition between the start and end image"
                     style={{"width": "100%"}}
                />
                <figcaption align='center'>
                    Choose 2 images where there is an obvious transition between the start and end image
                </figcaption>
            </figure>
            <figure>
                <img src="images/does_donts/image-2-does.png"
                     alt="Starting from a zoomed-in image and ending on a zoomed-out image usually works pretty well"
                     style={{"width": "100%"}}
                />
                <figcaption align='center'>
                    Starting from a zoomed-in image and ending on a zoomed-out image usually works pretty well
                </figcaption>
            </figure>
        </div>
    </CustomModal>


}