import {getAPIConfig, getAPIDeletePlanConfig} from "../app.config";
import axios from "axios";

export const getSettings = async (params) => {
    const verificationKey = btoa(params).toString('base64');
    const envKey = 'REACT_APP_API_ADMIN_SETTINGS_PATH';
    const axiosConfig = getAPIConfig(envKey, verificationKey, 'GET');
    let data = {data: [], error: ''};

    const response = await axios(axiosConfig);
    const responseData = await response.data;


    if (responseData.status) {
        data.data = responseData.data;
        data.error = '';
    } else {
        data.data = null;
        data.error = responseData.message;
    }

    return data;
}

export const updateSettings = async (params,inputData) => {
    const verificationKey = btoa(params).toString('base64');
    const envKey = 'REACT_APP_API_ADMIN_UPDATE_SETTINGS_PATH';
    const axiosConfig = getAPIConfig(envKey, verificationKey, 'POST',inputData);
    let data = {data: [], error: ''};

    const response = await axios(axiosConfig);
    const responseData = await response.data;

    if (responseData.status) {
        data.data = responseData.data;
        data.error = '';
    } else {
        data.data = null;
        data.error = responseData.message;
    }

    return data;
}


export const predictionJobsApi = async (params) => {
    const verificationKey = btoa(params).toString('base64');
    const envKey = 'REACT_APP_API_ADMIN_PREDICTION_JOBS_PATH';
    const axiosConfig = getAPIConfig(envKey, verificationKey, 'POST');
    let data = {data: [], error: ''};

    const response = await axios(axiosConfig);
    const responseData = await response.data;

    if (responseData.status) {
        data.data = responseData.data;
        data.error = '';
    } else {
        data.data = null;
        data.error = responseData.message;
    }

    return data;
}


export const generatedPredictionApi = async (params, inputData) => {
    const verificationKey = btoa(params).toString('base64');
    const envKey = 'REACT_APP_API_ADMIN_GENERATED_PREDICTION_PATH';
    const axiosConfig = getAPIConfig(envKey, verificationKey, 'POST',inputData);
    let data = {data: [], error: ''};

    const response = await axios(axiosConfig);
    const responseData = await response.data;

    if (responseData.status) {
        data.data = responseData.data;
        data.error = '';
    } else {
        data.data = null;
        data.error = responseData.message;
    }

    return data;
}


export const resetPredictionJobs = async (params) => {
    const verificationKey = btoa(params).toString('base64');
    const envKey = 'REACT_APP_API_ADMIN_RESTART_PREDICTION_JOBS_PATH';
    const axiosConfig = getAPIConfig(envKey, verificationKey, 'POST');
    let data = {data: [], error: ''};

    const response = await axios(axiosConfig);
    const responseData = await response.data;

    if (responseData.status) {
        data.data = responseData.data;
        data.error = '';
    } else {
        data.data = null;
        data.error = responseData.message;
    }

    return data;
}



