import Panel from "rsuite/Panel";
import {Button, Table, Toggle} from "rsuite";
import {useNavigate} from "react-router-dom";
import React, {Fragment, useEffect, useState} from "react";
import {getAuthSession} from "../../../servies/common.services";
import ToasterMessage from "../../common/toaster_message";
import CustomDrawer from "../../common/custom_drawer";
import CreatePaymentPlan from "./CreatePaymentPlan";
import Trash from '@rsuite/icons/legacy/Trash';
import {changeDefaultActivePaymentPlan, getPaymentPlan, updatePaymentPlan} from "../../../servies/paymentPlan.services";

const {Column, HeaderCell, Cell} = Table;

export const ViewPaymentPlan = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isSettingActive, setIsSettingActive] = useState(false);
    const [isSettingDefault, setIsSettingDefault] = useState(false);
    const [paramsData, setParamsData] = useState('');
    const [paymentPlans, setPaymentPlans] = useState([]);
    const [authData, setAuthData] = useState({});
    const [openCreatePlan, setOpenCreatePlan] = React.useState(false);
    const [placement, setPlacement] = React.useState();
    const [selectedRow, setSelectedRow] = useState({});
    const navigate = useNavigate();


    const handleOpen = (key, isEdit) => {
        setOpenCreatePlan(true);
        !isEdit ? setSelectedRow(null) : setSelectedRow(selectedRow);
        setPlacement(key);
    };

    const UpdateDefaultActiveStatus = (plan_id, change_type,value) => {
        const InputData = {
            plan_id,
            change_type,
        }

        if (change_type === 'active') {
            setIsSettingActive(true);
            InputData['value'] = value;
        }else if(change_type === 'default'){
            setIsSettingDefault(true);
        }


        changeDefaultActivePaymentPlan(paramsData, InputData)
            .then((res) => {
                const data = res.data;
                if (data === null) {
                    ToasterMessage.error(res.error);
                    return;
                }


                const plan = paymentPlans.find((plan) => plan.id === plan_id);


                if (change_type === 'active') {
                    plan.is_active = value;
                    setPaymentPlans([...paymentPlans]);

                }else if(change_type === 'default'){
                    const tempPaymentPlan = paymentPlans.map((plan) => {
                        if (plan.id === plan_id){
                            plan.is_default = true;
                        }else {
                            plan.is_default = false;
                        }
                        return plan;
                    });

                    setPaymentPlans([...tempPaymentPlan]);


                }



                ToasterMessage.success(`Update Plan Successfully`);
            })
            .catch(reason => {
                ToasterMessage.error(reason.toString() + 'Update Plan');
                console.log(reason);
            })
            .finally(() => {
                setIsSettingActive(false);
                setIsSettingDefault(false);
            });
    }


    useEffect(() => {
        const authSession = getAuthSession();
        if (authSession === null) {
            navigate('/admin/login');
            return;
        }

        if (openCreatePlan){
            return;
        }

        const token = authSession.token;
        setAuthData(authSession);

        const params = `token=${token}&is_admin=true`;
        setParamsData(params);
        setIsLoading(true);

        getPaymentPlan(params.toString(), true)
            .then(res => {
                const data = res.data;
                if (data === null) {
                    ToasterMessage.error(res.error);
                    return;
                }

                setPaymentPlans(data);

            })
            .catch(reason => {
                ToasterMessage.error(reason.toString() + 'Payment Plan');
                console.log(reason);
            })
            .finally(() => setIsLoading(false));

    }, [openCreatePlan,])

    return (
        <Fragment>
            <Panel>
                <Button appearance='primary' Style='margin-bottom: 10px' onClick={() => handleOpen('bottom', false)}>Create
                    Payment Plan</Button>
                <CustomDrawer backdrop={'static'} size={'calc(100% - 80px)'} placement={placement} open={openCreatePlan}
                              setOpen={setOpenCreatePlan} title='Payment Plan'>
                    <CreatePaymentPlan
                        selectedRow={selectedRow}
                        setSelectedRow={setSelectedRow}
                        params={paramsData}
                        setTableLoading={setIsLoading}
                        setOpen={setOpenCreatePlan}
                    />
                </CustomDrawer>
                <Table
                    height={'700'}
                    width={'100%'}
                    data={paymentPlans}
                    loading={isLoading}
                    onRowClick={data => {
                        setSelectedRow(data);
                    }}
                >
                    <Column>
                        <HeaderCell>ID</HeaderCell>
                        <Cell dataKey="id"/>
                    </Column>

                    <Column>
                        <HeaderCell>Name</HeaderCell>
                        <Cell dataKey="name"/>
                    </Column>

                    <Column>
                        <HeaderCell>Price</HeaderCell>
                        <Cell dataKey='price'/>
                    </Column>

                    <Column resizable={true}>
                        <HeaderCell>Total Predictions</HeaderCell>
                        <Cell dataKey="total_predictions"/>
                    </Column>

                    <Column >
                        <HeaderCell>Active</HeaderCell>
                        <Cell style={{padding: '6px'}}>
                            {rowData => (
                                <div>
                                    <Toggle
                                        loading={isSettingActive && selectedRow.id === rowData.id}
                                        checked={rowData.is_active}

                                        checkedChildren="Active"
                                        unCheckedChildren="Inactive"
                                        onChange={(value) => {
                                            setSelectedRow(rowData)
                                            UpdateDefaultActiveStatus(rowData.id, 'active',value);
                                        }}
                                    />
                                </div>
                            )}
                        </Cell>
                    </Column>

                    <Column>
                        <HeaderCell>Is Default</HeaderCell>
                        <Cell style={{padding: '6px'}}>
                            {rowData => (
                                <div>
                                    <Toggle
                                        loading={isSettingDefault && selectedRow.id === rowData.id}
                                        checked={rowData.is_default}

                                        checkedChildren="Default"
                                        unCheckedChildren="Normal"
                                        disabled={rowData.is_default}
                                        onChange={(value) => {
                                            setSelectedRow(rowData)
                                            UpdateDefaultActiveStatus(rowData.id, 'default',value);
                                        }}
                                    />
                                </div>
                            )}
                        </Cell>
                    </Column>

                    <Column fullText={true} resizable={true}>
                        <HeaderCell>Descriptions</HeaderCell>
                        <Cell dataKey="description"/>
                    </Column>

                    <Column fixed="right" resizable={true} >
                        <HeaderCell>Actions</HeaderCell>

                        <Cell style={{padding: '6px'}}>
                            {rowData => (
                                <div>
                                    <Button
                                        appearance="link"
                                        onClick={() => {
                                            setSelectedRow(rowData);
                                            handleOpen('bottom', true);
                                        }}
                                    >
                                        Edit
                                    </Button>
                                    <Button appearance="link" onClick={() => alert(`id:${rowData.id}`)}>
                                        <Trash/>
                                    </Button>
                                </div>
                            )}
                        </Cell>
                    </Column>

                </Table>

            </Panel>
        </Fragment>
    )
}