import {Schema} from "rsuite";
const {StringType, NumberType, ArrayType, ObjectType, DateType,BooleanType} = Schema.Types;

const CreatePaymentPlanModel = Schema.Model({
    name: StringType().isRequired('This field is required.'),
    price: NumberType().isRequired('This field is required.'),
    total_predictions: NumberType().isRequired('This field is required.'),
    description: StringType().isRequired('This field is required.'),
    is_active: BooleanType().isRequired('This field is required.'),
});

export default CreatePaymentPlanModel;