import {Col, Divider, Grid, Panel, Row, TagInput, toaster} from "rsuite";
import React, {useEffect, useRef, useState} from "react";
import {Form, ButtonToolbar, Button, Input, Toggle} from 'rsuite';
import CreatePaymentPlanModel from "../../../form_models/payment_plan_model";
import {createPaymentPlan, updatePaymentPlan} from "../../../servies/paymentPlan.services";
import ToasterMessage from "../../common/toaster_message";


const CreatePaymentPlan = ({params, setTableLoading, setOpen,setSelectedRow,selectedRow = null}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const formRef = useRef();
    const [formError, setFormError] = useState({});
    const [isEdit, setIsEdit] = useState(false)
    const defaultFormValue = {
        id: null,
        name: '',
        price: 0,
        total_predictions: 0,
        description: '',
        is_active: true,
        is_default: false,
    }
    const [formData, setFormData] = useState(defaultFormValue);

    const CustomTagInputControl = (props) => {
        const {value, ...rest} = props;


        return (
            <TagInput
                {...rest}
                value={value}
            />
        );
    }

    useEffect(() => {
        if (selectedRow !== null) {
            setIsEdit(true);
            setFormData({
                id: selectedRow.id,
                name: selectedRow.name,
                price: selectedRow.price,
                total_predictions: selectedRow.total_predictions,
                description: selectedRow.description,
                is_active: selectedRow.is_active === 1,
                is_default: selectedRow.is_default === 1,
            });
        }

        console.log('formData', formData)
    }, []);

    const handleSubmit = async (e) => {

        console.log('Form Data', formData);

        if (!formRef.current.check()) {
            setIsSubmitted(false)
            console.error('Form Error');
            return;
        }
        setIsLoading(true);
        toaster.clear();

        const inputData = {
            name: formData.name,
            price: formData.price,
            total_predictions: formData.total_predictions,
            description: formData.description,
            is_active: formData.is_active,
            is_default: formData.is_default,
        }

        let CreateOrUpdate = null;
        let showMessage = '';
        if (selectedRow === null) {
            CreateOrUpdate = createPaymentPlan;
            showMessage = 'Plan Created Successfully';
        } else {
            CreateOrUpdate = updatePaymentPlan;
            inputData.id = selectedRow.id;
            showMessage = 'Plan Updated Successfully';
        }

        CreateOrUpdate(params, inputData)
            .then((res) => {
                setTableLoading(true);
                const data = res.data;
                if (data === null) {
                    ToasterMessage.error(res.error + ' Create Plan');
                    return;
                }
                setFormData(defaultFormValue);
                setIsLoading(false);
                setTableLoading(false);
                ToasterMessage.success(showMessage);
            })
            .catch((error) => {
                console.log(error);
                ToasterMessage.error('Error in creating Plan');
                setIsLoading(false);
            })
            .finally(() => {
                setIsLoading(false);
                setIsSubmitted(false);
                setOpen(false);
                setSelectedRow(null);
            });


    }


    return (
        <Panel header={<h3 className="title">{isEdit ? 'Update' : 'Create'} Payment Plan</h3>}>
            <Form
                ref={formRef}
                onCheck={setFormError}
                formValue={formData}
                onChange={setFormData}
                // model={CreatePaymentPlanModel}
                disabled={isSubmitted}
            >
                <Grid fluid>
                    <Row>
                        <Col xs={24} sm={24} md={8}>
                            <Form.Group controlId="name">
                                <Form.ControlLabel>Name</Form.ControlLabel>
                                <Form.Control name="name" type="text" value={formData.name}/>
                                <Form.HelpText tooltip>Payment Plan Name is required</Form.HelpText>
                            </Form.Group>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                            <Form.Group controlId="price">
                                <Form.ControlLabel>Price</Form.ControlLabel>
                                <Form.Control name="price" value={formData.price}/>
                                <Form.HelpText tooltip>Price is required</Form.HelpText>
                            </Form.Group>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                            <Form.Group controlId="total_predictions">
                                <Form.ControlLabel>Total Predictions</Form.ControlLabel>
                                <Form.Control name="total_predictions" value={formData.total_predictions}/>
                                <Form.HelpText tooltip>Total Predictions is required</Form.HelpText>
                            </Form.Group>
                        </Col>
                        <Col xs={24} sm={24} md={2}>
                            <Form.Group controlId="is_active">
                                <Form.ControlLabel>Active</Form.ControlLabel>
                                <Form.Control
                                    name="is_active"
                                    checked={formData.is_active}
                                    accepter={Toggle}
                                    checkedChildren="Active"
                                    unCheckedChildren="Inactive"
                                    size={'md'}
                                />
                                <Form.HelpText tooltip>Active is required</Form.HelpText>
                            </Form.Group>
                        </Col>
                        {/*<Col xs={24} sm={24} md={2}>
                            <Form.Group controlId="is_active">
                                <Form.ControlLabel>Default</Form.ControlLabel>
                                <Form.Control
                                    name="is_default"
                                    checked={formData.is_default}
                                    accepter={Toggle}
                                    checkedChildren="Default"
                                    unCheckedChildren="Normal"
                                    size={'md'}
                                />
                                <Form.HelpText tooltip>Default is required</Form.HelpText>
                            </Form.Group>
                        </Col>*/}
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={24}>
                            <Form.Group controlId="description">
                                <Form.ControlLabel>Description</Form.ControlLabel>
                                <Form.Control
                                    name="description"
                                    type="text"
                                    value={formData.description}
                                    style={{width: '800px', height: '100px'}}
                                    placeholder="Enter description"
                                />
                                <Form.HelpText tooltip>Check description is required</Form.HelpText>
                            </Form.Group>
                        </Col>
                    </Row>

                    <br/>
                    <Row>
                        <Col xs={24} sm={24} md={8}>
                            <Form.Group>
                                <ButtonToolbar>
                                    <Button
                                        appearance="primary"
                                        onClick={handleSubmit}
                                        loading={isLoading}>Submit</Button>
                                    <Button appearance="default"
                                            onClick={(e) => setFormData(defaultFormValue)}>Reset</Button>
                                </ButtonToolbar>
                            </Form.Group>
                        </Col>
                    </Row>
                </Grid>
            </Form>

        </Panel>
    )
}

export default CreatePaymentPlan;