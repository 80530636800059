import {Button, Drawer, Placeholder} from "rsuite";
import {Page} from "@shopify/polaris";
import {useContext} from "react";
import {AppContext} from "../../lib/contextLib";

const CustomDrawer = ({backdrop, size, open, setOpen, placement, title, children}) => {
    const {
        isRefresh, setIsRefresh,
    } = useContext(AppContext);

    return (
        // <Page>
        <Drawer size={'full'} backdrop={backdrop} placement={placement} title={title} open={open}
                onClose={() => {
                    setIsRefresh(!isRefresh);
                    setOpen(false);
                }
                }>
            <Drawer.Header>
                <Drawer.Title>{title}</Drawer.Title>
                {/*<Drawer.Actions>*/}
                {/*    <Button onClick={() => setOpen(false)}>Cancel</Button>*/}
                {/*</Drawer.Actions>*/}
            </Drawer.Header>
            <Drawer.Body>
                {children}
            </Drawer.Body>
        </Drawer>
        /*</Page>*/
    );
}

export default CustomDrawer;