import { toaster, Notification} from 'rsuite';
import React, {forwardRef} from "react";


const Message = forwardRef(({type, ...rest}, ref) => {
    return (
        <Notification ref={ref} {...rest} type={type} header={type}>
            {rest.children}
        </Notification>
    );
});

const ToasterMessage = {
    info: (message,duration=1000) => {
        // toaster.clear()
        toaster.push(<Message type="info" duration={duration}>{message}</Message>);
    },
    success: (message,duration=1000) => {
        // toaster.clear()
        toaster.push(<Message type="success" closable title="Error" duration={duration} children={message}/>);
    },
    error: (message,duration=1000) => {
        // toaster.clear()
        toaster.push(<Message type="error" closable title="Error" duration={duration} children={message}/>);
    },
    warning: (message,duration=1000) => {
        // toaster.clear()
        toaster.push(<Message type="warning" closable title="Error" duration={duration} children={message}/>);
    },
    close: () => {
        toaster.close();
    },
    clear: () => {
        toaster.clear();
    }
};


export default ToasterMessage;


