import React, {useContext, useEffect, useState} from "react";
import {
    BlockStack,
    Button,
    Card,
    Icon,
    IndexTable,
    InlineStack,
    MediaCard,
    ProgressBar,
    Text,
    Thumbnail
} from "@shopify/polaris";
import {Icon as RIcon} from '@rsuite/icons';
import {AppContext} from "../lib/contextLib";
import {
    getPredictionFeedbackApi,
    getPredictionStatusApi, sendFeedbackApi
} from "../servies/prediction.services";
import {facebookPublishImage} from "../servies/facebook.services";
import {handleMouseMoveZoom, handleMouseOutZoom} from "./common/util";
import {CustomModal} from "./common/common_modals";
import {useLocation} from "react-router-dom";
import {Badge, IconButton, Placeholder} from "rsuite";
import {ReactComponent as thumbsUpSvg} from "../assets/thumbs_up.svg";
import {ReactComponent as thumbsDownSvg} from "../assets/thumbs_down.svg";
import {ReactComponent as thumbsUpActiveSvg} from "../assets/thumb_up_active.svg";
import {ReactComponent as thumbsDownActiveSvg} from "../assets/thumb_down_active.svg";
import ToasterMessage from "./common/toaster_message";


export default function ViewDrawerIndexTableRow({
                                                    id,
                                                    productName,
                                                    predictionId,
                                                    predictionStatus,
                                                    state,
                                                    video,
                                                    userPrompt,
                                                    startImageUrl,
                                                    endImageUrl,
                                                    CreatedAt,
                                                    index
                                                }) {
    const {search} = useLocation();
    const params = new URLSearchParams(search);

    const [showError, setShowError] = useState(false);
    const [isShareOnInstagram, setIsShareOnInstagram] = useState(false);
    const [isCreatingAIVideo, setIsCreatingAIVideo] = useState(false);
    const [isGettingPredictionStatus, setGettingPredictionStatus] = useState(false)
    const [isThumbsUpSending, setIsThumbsUpSending] = useState(false);
    const [isThumbsDownSending, setIsThumbsDownSending] = useState(false);
    const [previousFeedback, setPreviousFeedback] = useState(null);
    const [isPreviousFeedbackLoading, setIsPreviousFeedbackLoading] = useState(false);


    const {
        selectedFacebookAccount, setSelectedFacebookAccount
    } = useContext(AppContext);

    const imagesStyle = {
        position: 'relative',
        cursor: 'pointer',
        marginBottom: '16px',
        borderRadius: '6px',
        width: '250px',
    }

    const thumbsStyle = {
        fontSize: 35,
        color: 'transparent',
        filter: 'invert(1)'
    }

    const onThumbMouseEnter = (e) => {
        const childern = e.target.children[0];
        if (childern) {
            childern.style = 'filter: invert(0); color: transparent; font-size: 35px;'
        }

        // e.target.style = 'filter: invert(0); color: transparent; font-size: 35px;'
    }

    const onThumbMouseLeave = (e) => {
        const childern = e.target.children[0];
        if (childern) {
            childern.style = 'filter: invert(1); color: transparent; font-size: 35px;'
        }
        // e.target.style = 'filter: invert(1); color: transparent; font-size: 35px;'
    }



    const getPredictionStatus = (predictionId) => {
        console.log(predictionId)
        const inputData = {prediction_id: predictionId}
        setGettingPredictionStatus(true)
        getPredictionStatusApi(params.toString(), inputData)
            .then(res => {
                const data = res.data;

                if (res.data === null) {
                    console.log('Error', res.error);
                    return;
                }

                console.log('data', data)
                const aiImages = data.ai_images;

                console.log('aiImages', aiImages)
                // setAIImagesData(aiImages)
                // setIsAIImagesData(aiImages.length > 0)


                // setPredictionPercentage(data.percentage_completed)
                // console.log('predictionPercentage', predictionPercentage)


            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
                setGettingPredictionStatus(false)
            })
    }

    const shareOnInstagram = async (url, caption) => {
        const inputData = {
            "account_id": selectedFacebookAccount,
            "image_url": url,
            "caption": caption
        }
        setIsShareOnInstagram(true);
        const response = await facebookPublishImage(params.toString(), inputData);
        setIsShareOnInstagram(false);
        if (response.data === null) {
            console.log('Error', response.error);
            return;
        }
        console.log('response', response.data);

        setShowError('');

    }

    const getPredictionFeedback = () => {
        const inputData = {
            prediction_id: id
        }
        setIsPreviousFeedbackLoading(true);
        getPredictionFeedbackApi(params.toString(), inputData)
            .then(res => {
                const data = res.data;
                if (data === null) {
                    // ToasterMessage.error(res.error);
                    return;
                }

                setPreviousFeedback(data);

                console.log('PreviousFeedback data', data)

            })
            .catch((e) => {
                const errorCode = e.code;
                const errorMessage = e.message;
                ToasterMessage.error(errorMessage);
                console.log(errorCode, errorMessage);
            })
            .finally(() => {
                setIsPreviousFeedbackLoading(false);
            })
    }

    useEffect(() => {
        // getProductPrediction(productId)
        getPredictionFeedback();

    }, []);

    const sendFeedback = (feedback) => {
        const inputData = {
            feedback: {
                prediction_id: id,
                is_thumbs_up: feedback === 'thumbs-up',
                description: feedback === 'thumbs-up' ? 'thumbs-up' : 'thumbs-down'
            }
        }


        if (feedback === 'thumbs-up') {
            setIsThumbsUpSending(true);
        }

        if (feedback === 'thumbs-down') {
            setIsThumbsDownSending(true);
        }

        sendFeedbackApi(params.toString(), inputData)
            .then(res => {
                    const data = res.data;
                    if (data === null) {
                        ToasterMessage.error(res.error);
                        return;
                    }
                    ToasterMessage.success(res.message);

                    setPreviousFeedback(inputData.feedback)
                }
            )
            .catch((e) => {
                const errorCode = e.code;
                const errorMessage = e.message;
                ToasterMessage.error(errorMessage);
                console.log(errorCode, errorMessage);
            })
            .finally(() => {
                setIsThumbsUpSending(false);
                setIsThumbsDownSending(false);
            })
    }

    return (
        <>
            <CustomModal
                title={`AI Video - ${productName}`}
                // open={isImageModal}
                // setOpen={setIsImageModal}
                leftButtonText={'Close'}
                // leftOnClick={() => setIsImageModal(false)}
            >
                <MediaCard
                    title={'AI Video'}
                    size={"medium"}
                    primaryAction={{
                        content: 'View/Download',
                        // onAction: () => window.open(selectedAiImage, '_blank')
                    }}
                >
                    <img
                        alt=""
                        width="100%"
                        height="100%"
                        style={{
                            objectFit: 'cover',
                            objectPosition: 'center',
                        }}
                        onMouseMove={handleMouseMoveZoom}
                        onMouseOut={handleMouseOutZoom}
                        // src={selectedAiImage}
                    />
                </MediaCard>
            </CustomModal>

            <IndexTable.Row
                id={id}
                predictionId={predictionId}
                predictionStatus={predictionStatus}
                state={state}
                startImageUrl={startImageUrl}
                endImageUrl={endImageUrl}
                userPrompt={userPrompt}
                video={video}
                productName={productName}
                CreatedAt={CreatedAt}
                key={id}
                position={index}
            >
                <IndexTable.Cell>
                    <InlineStack align={'start'} gap={'200'}>
                        <div
                            style={imagesStyle}
                            onMouseMove={handleMouseMoveZoom}
                            onMouseOut={handleMouseOutZoom}
                        >
                            <Card>
                                <img
                                    src={startImageUrl}
                                    alt={productName}
                                    style={{width: '100%'}}
                                />
                            </Card>

                        </div>
                    </InlineStack>
                </IndexTable.Cell>

                <IndexTable.Cell>
                    <InlineStack align={'start'} gap={'200'}>
                        <div
                            style={imagesStyle}
                            onMouseMove={handleMouseMoveZoom}
                            onMouseOut={handleMouseOutZoom}
                        >
                            <Card>
                                <img
                                    src={endImageUrl ? endImageUrl : 'images/no_image.jpg'}
                                    alt={productName}
                                    style={{width: '100%'}}
                                />
                            </Card>

                        </div>
                    </InlineStack>
                </IndexTable.Cell>

                <IndexTable.Cell>
                    <div
                        style={imagesStyle}
                    >
                        {state === 'completed' ?
                            <Card>

                                <InlineStack align={'start'} gap={'200'}>
                                    <video width="100%" controls>
                                        <source src={video.url} type="video/mp4"/>
                                    </video>
                                </InlineStack>

                            </Card>
                            :
                            <Placeholder.Graph active width={320} height={240}/>
                        }
                    </div>

                </IndexTable.Cell>

                <IndexTable.Cell>


                    <Text as={'span'}>{CreatedAt}</Text>
                    <InlineStack align={'start'} gap={'200'}>
                        {

                            isPreviousFeedbackLoading ?
                                <Placeholder.Graph active width={100} height={40}/>
                                :
                                previousFeedback !== null ?
                                    <IconButton
                                        disabled={true}
                                        size={'sm'}
                                        icon={
                                            <RIcon
                                                as={previousFeedback.is_thumbs_up ? thumbsUpActiveSvg : thumbsDownActiveSvg}
                                                // style={thumbsStyle}
                                                style={{
                                                    fontSize:35
                                                }}
                                                color={'transparent'}
                                            />
                                        }
                                        onClick={() => sendFeedback('thumbs-up')}
                                    />
                                    :
                                    <>
                                        <IconButton
                                            loading={isThumbsUpSending}
                                            disabled={isThumbsDownSending || isThumbsUpSending || previousFeedback !== null}
                                            className={'.thumbs-up'}
                                            size={'sm'}
                                            onMouseEnter={onThumbMouseEnter}
                                            onMouseLeave={onThumbMouseLeave}
                                            icon={
                                                <RIcon
                                                    as={thumbsUpSvg}
                                                    style={thumbsStyle}


                                                />
                                            }
                                            onClick={() => sendFeedback('thumbs-up')}
                                        />


                                        <IconButton
                                            loading={isThumbsDownSending}
                                            disabled={isThumbsDownSending || isThumbsUpSending || previousFeedback !== null}
                                            size={'sm'}
                                            onMouseEnter={onThumbMouseEnter}
                                            onMouseLeave={onThumbMouseLeave}
                                            icon={
                                                <RIcon
                                                    as={thumbsDownSvg}
                                                    style={thumbsStyle}

                                                />
                                            }
                                            onClick={() => sendFeedback('thumbs-down')}
                                        />
                                    </>

                        }
                    </InlineStack>


                </IndexTable.Cell>
            </IndexTable.Row>
        </>
    )
}
