import {
    BlockStack, Button, Divider, IndexTable, InlineStack, ProgressBar, Text, Thumbnail, Modal, MediaCard, Card
} from "@shopify/polaris";

import {ImageAddIcon, ViewIcon} from "@shopify/polaris-icons";
import React, {useContext, useEffect, useState} from "react";
import {createPredictionApi, getPredictionStatus} from "../servies/prediction.services";
import {useLocation} from "react-router-dom";
import {ViewDrawer} from "./ViewDrawer";
import {handleMouseMoveZoom, handleMouseOutZoom} from "./common/util";
import {CreateDrawer} from "./CreateDrawer";
import {AppContext} from "../lib/contextLib";

export default function ProductsIndexTableRow({
                                                  productID,
                                                  productName,
                                                  productImage,
                                                  productImages,
                                                  predictions,
                                                  isPredictionCreated,
                                              }, index, selectedProduct) {
    const {search} = useLocation();
    const params = new URLSearchParams(search);
    const [isViewDrawerOpen, setIsViewDrawerOpen] = useState(false);
    const [isCreateDrawerOpen, setIsCreateDrawerOpen] = useState(false);
    const [selectedProductID, setSelectedProductID] = useState(null);

    const {
        isRefresh, setIsRefresh,
        shopState, setShopState,
        bannerMessage, setBannerMessage,
        openPlanModal, setOpenPlanModal
    } = useContext(AppContext);


    const handelOnCreateButton = () => {
        console.log('shopState.predictions_left',shopState.predictions_left)

        if(shopState.predictions_left < 1){
            const bannerData = {
                title:'Upgrade needed!',
                tone:'warning',
                actionCallback: () => {
                    setOpenPlanModal(true);
                    console.log('Upgrade needed! pressed')
                },
                actionContent: 'Upgrade',
                description: 'You have reached your limit of AI images. Please upgrade your plan to create more AI images.'
            }
            setBannerMessage(bannerData)
            return;
        }


        setSelectedProductID(productID)
        setIsCreateDrawerOpen(true)


    }

    useEffect(() => {
    }, [])


    return (<>
            {isViewDrawerOpen ? <ViewDrawer
                productID={productID}
                productName={productName}
                productImage={productImage}
                productImages={productImages}
                predictions={predictions}
                isPredictionCreated={isPredictionCreated}

                isImageModalOpen={isViewDrawerOpen}
                setIsImageModalOpen={setIsViewDrawerOpen}
                selectedProductID={selectedProductID}
                setSelectedProductID={setSelectedProductID}
                view={1}

            /> : null}

                {isCreateDrawerOpen ? <CreateDrawer
                    productID={productID}
                    productName={productName}
                    productImage={productImage}
                    productImages={productImages}
                    predictions={predictions}
                    isPredictionCreated={isPredictionCreated}

                    isImageModalOpen={isCreateDrawerOpen}
                    setIsImageModalOpen={setIsCreateDrawerOpen}
                    selectedProductID={selectedProductID}
                    setSelectedProductID={setSelectedProductID}
                    view={1}

                /> : null}

                <IndexTable.Row
                    id={productID}

                    productName={productName}
                    productImage={productImage}
                    productImages={productImages}
                    predictions={predictions}
                    isPredictionCreated={isPredictionCreated}

                    selected={selectedProduct}
                    key={productID}
                    position={index}
                >
                    <IndexTable.Cell>
                        <InlineStack align={'start'} gap={'200'}>
                            <div
                                onMouseMove={handleMouseMoveZoom}
                                onMouseOut={handleMouseOutZoom}
                            >
                                <Thumbnail
                                    source={productImage}
                                    alt={productName}
                                    size="large"
                                />
                            </div>
                            <Text variant="bodyMd" fontWeight="bold" as="span">
                                {productName}
                            </Text>
                        </InlineStack>
                    </IndexTable.Cell>

                    <IndexTable.Cell>
                        {isPredictionCreated ? <Button
                            icon={ViewIcon}
                            variant={'secondary'}
                            onClick={() => {
                                setSelectedProductID(productID)
                                setIsViewDrawerOpen(true)
                            }}
                            // loading={isCreatingAiImage || isPredicting}
                        >
                            View AI Videos
                        </Button> : null}

                        <Button
                            icon={ImageAddIcon}
                            variant={'secondary'}
                            onClick={handelOnCreateButton}
                            // loading={isCreatingAiImage || isPredicting}
                        >
                            Create AI Videos
                        </Button>
                    </IndexTable.Cell>
                </IndexTable.Row>
        </>);

}