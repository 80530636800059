

const Error404 = () => {
  return (
    <div className="error404">
      <h1>404</h1>
      <h2>Page not found!</h2>
    </div>
  );
}


export default Error404;