import {
    Avatar,
    BlockStack, Button,
    Card,
    Divider, Grid, Icon, IndexTable, InlineGrid,
    InlineStack,
    MediaCard,
    Modal, ProgressBar, ResourceItem,
    ResourceList, Spinner,
    Text,
    Thumbnail,
    Badge as PolarisBadge
} from "@shopify/polaris";
import {ImageAddIcon, ImageIcon, LogoInstagramIcon, RefreshIcon} from '@shopify/polaris-icons';
import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../lib/contextLib";
import {useLocation} from "react-router-dom";
import CustomDrawer from "./common/custom_drawer";
import {getShopifyProductImagesAPI} from "../servies/products.services";

import {createPredictionApi, getPredictionStatus} from "../servies/prediction.services";

import {
    Badge,
    Col,
    RadioTile,
    RadioTileGroup,
    Row,
    Stack,
    useMediaQuery,
    Button as RSuiteButton,
    Button as RsuiteButton
} from "rsuite";
import * as PropTypes from "prop-types";
import {handleMouseMoveZoom, handleMouseOutZoom} from "./common/util";
import {CustomModal} from "./common/common_modals";
import {PlanModal} from "./modal/PlanModal";
import {TipModal} from "./modal/TipModal";


function SpacingBackground(props) {
    return null;
}

SpacingBackground.propTypes = {children: PropTypes.node};
export const CreateDrawer = ({
                                 productName,
                                 isImageModalOpen,
                                 setIsImageModalOpen,
                                 selectedProductID,

                                 view
                             }) => {
    const {search} = useLocation();
    const params = new URLSearchParams(search);
    const [loading, setLoading] = useState(false);
    const [isCreationLoading, setIsCreationLoading] = useState(false);
    const [productImages, setProductImages] = useState([]);
    const [isInline] = useMediaQuery('xl');
    const [startImageId, setStartImageId] = useState(null);
    const [endImageId, setEndImageId] = useState(null);
    const [startImageSrc, setStartImageSrc] = useState(null);
    const [endImageSrc, setEndImageSrc] = useState(null);
    const [totalSelectedImage, setTotalSelectedImage] = useState(0);
    const [selectedImages, setSelectedImages] = useState([]);
    const [userPrompt, setUserPrompt] = useState('');
    const [openTipModal, setOpenTipModal] = useState(false);


    const {
        isRefresh, setIsRefresh,
        shopState, setShopState,
        bannerMessage, setBannerMessage,
        openPlanModal, setOpenPlanModal
    } = useContext(AppContext);

    const handleSelectImage = (id, src) => {
        console.log(id)
        if (startImageId === id) {
            setStartImageId(null);
        } else if (endImageId === id) {
            setEndImageId(null);
        } else if (startImageId === null) {
            setStartImageSrc(src);
            setStartImageId(id);
        } else if (endImageId === null) {
            setEndImageSrc(src);
            setEndImageId(id);
        }
    };

    const getBadgeContent = (id) => {

        if (startImageId === id) return 'Start Image';
        if (endImageId === id) return 'End Image';
        return null;
    };

    const handleMakeAiVideo = () => {
        const inputData = {
            'product_id': selectedProductID,
            'start_image_id': startImageId,
            'end_image_id': endImageId,
            'start_image_src': startImageSrc,
            'end_image_src': endImageSrc,
            'user_prompt': userPrompt
        }

        setIsCreationLoading(true);

        createPredictionApi(params.toString(), inputData)
            .then(res => {
                const data = res.data;
                console.log(data)
                if (res.error !== '') {
                    console.log('Error', res.error);
                    return;
                }

            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
                const bannerData = {
                    title: 'Your AI video is being created',
                    tone: 'success',
                    description: `<b>${shopState.email}</b> will get emailed when the AI video is ready for the <b>${productName}</b>`
                }
                setIsRefresh(true)
                setBannerMessage(bannerData)
                setIsImageModalOpen(false)
                setIsCreationLoading(false)

            })


    }

    useEffect(() => {
        const inputData = {
            product_id: selectedProductID
        }
        setLoading(true)

        getShopifyProductImagesAPI(params.toString(), inputData)
            .then(res => {
                const data = res.data;
                console.log(data)
                if (res.error !== '') {
                    console.log('Error', res.error);
                    return;
                }
                console.log('data', data)
                const images = data.images;
                setProductImages(images);
                console.log('aiImages', images)

            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => setLoading(false))
    }, []);

    return <>
        <TipModal
            title={'Which image work best for AI Video'}
            open={openTipModal}
            setOpen={setOpenTipModal}

        />
        <CustomDrawer
            placement={'bottom'}
            size={'calc(100% - 120px)'}
            open={isImageModalOpen}
            setOpen={setIsImageModalOpen}
            title={productName}
        >
            {loading ? <Spinner/> : null}


            <div style={{padding: '20px'}}>
                {
                    shopState.predictions_left < 1 ?
                        null :
                        <Button
                            // appearance="primary"
                            onClick={handleMakeAiVideo}
                            disabled={productImages.length > 1 ? startImageId === null || endImageId === null : startImageId === null}
                            tone={!(productImages.length > 1 ? startImageId === null || endImageId === null : startImageId === null) && 'success'}
                            variant={'primary'}
                            loading={isCreationLoading}
                        >
                            Make AI Video
                        </Button>
                }
                <RSuiteButton
                    appearance="link"
                    hidden={shopState.predictions_left <= 1}
                    onClick={() => {
                        setOpenTipModal(true);
                    }}
                >
                    Tip
                </RSuiteButton>

                {
                    shopState.predictions_left <= 3 ?
                        <>
                            You have only <PolarisBadge
                            tone={'warning'}
                        >
                                <b>{shopState.predictions_left}</b>
                        </PolarisBadge> predictions left,
                            <RsuiteButton
                                appearance={'link'}
                                onClick={
                                    (e) => {
                                        setOpenPlanModal(true)
                                    }
                                }
                            >
                                <PolarisBadge
                                    tone={'warning'}
                                >
                                    Upgrade for more
                                </PolarisBadge>
                            </RsuiteButton>
                        </>

                        : null
                }
            </div>
            <Divider/>

            {/*<Row gutter={12}>*/}

            <div style={{padding: '20px'}}>
                <InlineStack gap={300} wrap={true} align={'space-evenly'}>
                    {productImages.map(({image_id, src}) => (

                        // <Col key={image_id} xs={12} sm={8} md={6} lg={4}  onClick={() => handleSelectImage(image_id)}>
                        <div
                            style={{
                                border: startImageId === image_id || endImageId === image_id ? '2px solid #FBBC04' : 'none',
                                padding: '10px',
                                position: 'relative',
                                cursor: 'pointer',
                                marginBottom: '16px',
                                borderRadius: '6px',
                                width: '250px',
                                // height: '200px',
                            }}
                            onClick={() => handleSelectImage(image_id, src)}
                        >
                            <Card>
                                <img src={src}
                                     alt={`Thumbnail ${image_id}`}
                                     style={{width: '100%', borderRadius: '6px'}}
                                />

                                {(startImageId === image_id || endImageId === image_id) && (
                                    <Badge
                                        content={getBadgeContent(image_id)}
                                        style={{position: 'absolute', top: '8px', right: '8px'}}
                                    />
                                )}
                            </Card>
                        </div>
                        // </Col>
                    ))}
                </InlineStack>
            </div>

            {/*</Row>*/}


        </CustomDrawer>
    </>
}