import {getAPIChangePlanConfig, getAPIConfig} from "../app.config";
import axios from "axios";

export const getShopData = async (params) => {
    const verificationKey = btoa(params).toString('base64');
    const envKey = 'REACT_APP_API_SHOP_DATA_PATH';
    const axiosConfig = getAPIConfig(envKey, verificationKey, 'POST');
    let data = {data: {}, error: ''};

    const response = await axios(axiosConfig);
    const responseData = await response.data;


    if (responseData.status) {
        data.data = responseData.data;
        data.error = '';
    } else {
        data.data = null;
        data.error = responseData.message;
    }

    return data;
}

export const getShopStats = async (params) => {
    const verificationKey = btoa(params).toString('base64');
    const envKey = 'REACT_APP_API_SHOP_STATS_PATH';
    const axiosConfig = getAPIConfig(envKey, verificationKey, 'POST');
    let data = {data: {}, error: ''};

    const response = await axios(axiosConfig);
    const responseData = await response.data;


    if (responseData.status) {
        data.data = responseData.data;
        data.error = '';
    } else {
        data.data = null;
        data.error = responseData.message;
    }

    return data;
}

export const updateShopData = async (params,inputData) => {
    const verificationKey = btoa(params).toString('base64');
    const envKey = 'REACT_APP_API_UPDATE_SHOP_PATH';
    const axiosConfig = getAPIConfig(envKey, verificationKey, 'POST',inputData);
    let data = {data: {}, error: ''};

    const response = await axios(axiosConfig);
    const responseData = await response.data;


    if (responseData.status) {
        data.data = responseData.data;
        data.error = '';
    } else {
        data.data = null;
        data.error = responseData.message;
    }

    return data;
}


export const changePlan = async (params, plan_id) => {
    const verificationKey = btoa(params).toString('base64');
    const axiosConfig = getAPIChangePlanConfig(verificationKey, plan_id);
    let data = {data: {}, error: ''};

    const response = await axios(axiosConfig);

    const responseData = await response.data;

    if (responseData.status) {
        data.data = responseData.data;
        data.error = '';
    } else {
        data.data = null;
        data.error = responseData.message;
    }

    return data;
}