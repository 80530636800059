import {getAPIChangePlanConfig, getAPIConfig} from "../app.config";
import axios from "axios";

export const getProductsAPI = async (params, inputData) => {
    const verificationKey = btoa(params).toString('base64');
    const envKey = 'REACT_APP_API_PRODUCTS_PATH';
    const axiosConfig = getAPIConfig(envKey, verificationKey, 'POST',inputData);
    let data = {data: {}, error: ''};

    const response = await axios(axiosConfig);
    const responseData = await response.data;


    if (responseData.status) {
        data.data = responseData.data;
        data.error = '';
    } else {
        data.data = null;
        data.error = responseData.message;
    }

    return data;
}


export const getShopifyProductsAPI = async (params,inputData) => {
    const verificationKey = btoa(params).toString('base64');
    const envKey = 'REACT_APP_API_SHOPIFY_PRODUCTS_PATH';
    const axiosConfig = getAPIConfig(envKey, verificationKey, 'POST',inputData);
    let data = {data: {}, error: ''};

    const response = await axios(axiosConfig);
    const responseData = await response.data;

    if (responseData.status) {
        data.data = responseData.data;
        data.error = '';
    } else {
        data.data = null;
        data.error = responseData.message;
    }

    return data;
}

export const getGeneratedProductsAPI = async (params,inputData) => {
    const verificationKey = btoa(params).toString('base64');
    const envKey = 'REACT_APP_API_GENERATED_PRODUCTS_PATH'
    const axiosConfig = getAPIConfig(envKey, verificationKey, 'POST',inputData);
    let data = {data: {}, error: ''};

    const response = await axios(axiosConfig);
    const responseData = await response.data;

    if (responseData.status) {
        data.data = responseData.data;
        data.error = '';
    } else {
        data.data = null;
        data.error = responseData.message;
    }

    return data;
}

export const getShopifyProductImagesAPI = async (params,inputData) => {
    const verificationKey = btoa(params).toString('base64');
    const envKey = 'REACT_APP_API_SHOPIFY_PRODUCTS_IMAGES_PATH';
    const axiosConfig = getAPIConfig(envKey, verificationKey, 'POST',inputData);
    let data = {data: {}, error: ''};

    const response = await axios(axiosConfig);
    const responseData = await response.data;

    if (responseData.status) {
        data.data = responseData.data;
        data.error = '';
    } else {
        data.data = null;
        data.error = responseData.message;
    }

    return data;
}