import React, {Fragment, useCallback, useContext, useState} from 'react';
import {
    Autocomplete,
    Banner,
    Bleed,
    Card,
    Icon,
    InlineStack,
    Layout,
    Page,
    Tabs,
    Text,
    TextField,
} from '@shopify/polaris';

import '../static/chat.css';
import {useEffect} from "react";
import {useLocation} from "react-router-dom";
import {AppContext} from "../lib/contextLib";
import {redirect} from "@shopify/app-bridge/client/redirect";
import {getShopData, getShopStats, updateShopData} from "../servies/shop.services";
import {Placeholder} from "../components/common/util";
import CustomIndexTable from "../components/CustomIndexTable";
// import {useProfile, LoginButton, useFacebook} from 'react-facebook';
import {getFacebookAccounts} from "../servies/facebook.services";
import TopSection from "../components/TopSection";
import {PlanModal} from "../components/modal/PlanModal";
import {SearchIcon} from "@shopify/polaris-icons";


export default function Dashboard() {
    const {search} = useLocation();
    const params = new URLSearchParams(search);
    const [title, setTitle] = useState('');
    const [selected, setSelected] = useState(1);
    const [products, setProducts] = useState([]);
    const [isProductLoading, setIsProductLoading] = useState(false);
    const [facebookAccessToken, setFacebookAccessToken] = useState('');
    const [isFacebookLogin, setIsFacebookLogin] = useState(false);
    const [isFacebookAccountsLoading, setIsFacebookAccountsLoading] = useState(false);
    const [showBanner, setShowBanner] = useState(false);
    const [isStatsLoading, setIsStatsLoading] = useState(false);


    const {
        shopState, setShopState,
        facebookAccounts, setFacebookAccounts,
        selectedFacebookAccount, setSelectedFacebookAccount,
        totalAIImagesCount, setTotalAIImagesCount,
        totalProductGenerated, setTotalProductGenerated,
        totalProducts, setTotalProducts,
        bannerMessage, setBannerMessage,
        openPlanModal, setOpenPlanModal
    } = useContext(AppContext);

    // const {isLoading, init, error} = useFacebook();


    const facebook_scope = `instagram_basic,business_management,pages_manage_instant_articles,pages_show_list,instagram_content_publish,pages_read_engagement,pages_manage_metadata,pages_read_user_content,pages_manage_posts,pages_manage_engagement,whatsapp_business_messaging,instagram_manage_events,public_profile`;

    const handleSuccess = async (response) => {
        console.log(response);
        setIsFacebookLogin(true);
        const facebookToken = response.authResponse.accessToken
        setFacebookAccessToken(facebookToken);

        const inputData = {
            shop_data: {
                facebook_token: facebookToken
            }
        }
        const respose = await updateShopData(params.toString(), inputData)

        setIsFacebookLogin(false)
        if (respose.error !== '') {
            console.log('Error', respose.error);
            return;
        }
        console.log('respose', respose.data);

        setIsFacebookAccountsLoading(true);
        const accounts = await getFacebookAccounts(params.toString())
        setIsFacebookAccountsLoading(false);
        if (accounts.error !== '') {
            console.log('Error', accounts.error);
            return;
        }
        const optionData = accounts.data.map((item) => {
            return {label: item.name, value: item.instagram_business_account}
        })
        setFacebookAccounts(optionData);
    }

    const handleError = (error) => {
        console.log(error);
    }

    useEffect(() => {

        // if (bannerMessage !== null) {
        //     setShowBanner(true);
        // }


    }, [bannerMessage]);


    useEffect(() => {

        setIsStatsLoading(true)

        getShopStats(params.toString())
            .then(res => {
                const data = res.data;
                console.log(data)
                if (res.error !== '') {
                    console.log('shopStats', data);
                    // redirect('/not-auth');
                    return;
                }
                console.log('shopStats data', data)
                setShopState(data);

            })
            .catch((e) => {
                console.log(e);
                // redirect('/not-auth');
            })
            .finally(() => setIsStatsLoading(false));

        /*getShopData(params.toString())
            .then(res => {
                const data = res.data;
                console.log(data)
                if (res.error !== '') {
                    console.log('shopData', data);
                    redirect('/not-auth');

                    return;
                }
                setShopData(data);
                setTitle(data.store_name + ' | ' + data.shop_owner);
                setFacebookAccessToken(data.facebook_token);

            })
            .catch((e) => {
                console.log(e);
                // redirect('/not-auth');
            })
            .finally(() => setIsShopLoading(false));*/

        /*
                setIsFacebookAccountsLoading(true);

                getFacebookAccounts(params.toString())
                    .then(res => {
                        const data = res.data;
                        console.log(data)
                        if (res.error !== '') {
                            console.log('Facebook Data', data);
                            // redirect('/not-auth');
                            return;
                        }
                        const optionData = data.map((item) => {
                            return {label: item.name, value: item.instagram_business_account}
                        })
                        setFacebookAccounts(optionData);
                        console.log('facebookAccounts', facebookAccounts);
                    })
                    .catch((e) => {
                        console.log(e);
                        // redirect('/not-auth');
                    })
                    .finally(() => setIsFacebookAccountsLoading(false));
        */

    }, []);


    const handleTabChange = useCallback((selectedTabIndex) => {
        console.log('selectedTabIndex', selectedTabIndex)
        setSelected(selectedTabIndex);

    }, [],);

    const tabs = [
        {
            id: 'all-products-1',
            content: 'All',
            accessibilityLabel: 'All products',
            panelID: 'all-products-content-1',
            badge: `${totalProducts}`,
        },
        {
            id: 'generated-images-1',
            content: 'Generated Videos',
            panelID: 'generated-videos-content-1',
            badge: `${totalProductGenerated}`,
        },
    ];

    const showBannerMessage = () => {
        let action = null
        if(bannerMessage.actionContent !== null && bannerMessage.actionContent !== undefined){
            action = {
                content: bannerMessage.actionContent,
                onAction() {
                    if (bannerMessage.actionCallback !== null && bannerMessage.actionCallback !== undefined) {
                        bannerMessage.actionCallback();
                    }
                }
            }
        }

        return (
            <div
                style={{
                    marginBottom: '20px'
                }}
            >
                <Banner
                    title={bannerMessage.title}
                    action={action}
                    tone={bannerMessage.tone}
                    onDismiss={
                        () => {
                            setBannerMessage(null);
                        }
                    }
                >
                    <div dangerouslySetInnerHTML={{__html: bannerMessage.description}}/>
                    <Text>{}</Text>
                </Banner>
            </div>
        )
    }


    return (
        <Fragment>
            <Page>
                {
                    openPlanModal ?
                        <PlanModal
                            params={params}
                            openModal={openPlanModal}
                            setOpenModal={setOpenPlanModal}
                        />
                        : null
                }
                {bannerMessage !== null ? showBannerMessage() : null}
                <Layout>
                    <TopSection/>
                    <Layout.Section secondary>

                        <Card>

                            <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
                                <Card title={tabs[selected].content}>
                                    <CustomIndexTable
                                        selectedTab={selected}
                                    />

                                </Card>
                            </Tabs>
                        </Card>

                    </Layout.Section>
                </Layout>
            </Page>
        </Fragment>


    );
}