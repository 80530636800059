import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {redirect} from "@shopify/app-bridge/client/redirect";

export default function ExitIframe() {
    const { search } = useLocation();

    useEffect(() => {
        (async function () {
            const params = new URLSearchParams(search);
            const redirectUri = params.get("redirectUri");

            console.log(params.get('embedded'));
            console.log(params.toString());

            const embedded = params.get('embedded');
            const access_token = params.get('access_token');
            const hmac = params.get('hmac');
            const host = params.get('host');
            const id_token = params.get('id_token');
            const locale = params.get('locale');
            const session = params.get('session');
            const shop = params.get('shop');
            const timestamp = params.get('timestamp');

            const finalParamObj = {
                embedded,
                hmac,
                host,
                id_token,
                locale,
                session,
                shop,
                timestamp
            }

            if (!redirectUri) {
                return;
            }

            const url = new URL(decodeURIComponent(redirectUri));

            if (
                [window.location.hostname, "admin.shopify.com"].includes(url.hostname) ||
                url.hostname.endsWith(".myshopify.com")
            ) {
                const paramObjStr = Object.keys(finalParamObj).map(key => finalParamObj[key] !== null ? `${key}=${finalParamObj[key]}` : '').join('&') ;
                const final_params = paramObjStr//`embedded=${embedded}&hmac=${hmac}&host=${host}&id_token=${id_token}&locale=${locale}&session=${session}&shop=${shop}&timestamp=${timestamp}`

                const final_redirect_uri = redirectUri + '?' + final_params;
                console.log('final_redirect_uri',final_redirect_uri);
                try {
                    redirect(final_redirect_uri);
                } catch (e) {
                    console.log(e);
                }
            }
        })();
    }, [search]);

    return <div>Redirecting...</div>;
}