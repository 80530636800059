import Panel from "rsuite/Panel";
import {Button, Form, Table, Toggle,Input} from "rsuite";
import {Link, useNavigate} from "react-router-dom";
import React, {Fragment, useEffect, useState} from "react";
import {getAuthSession} from "../../../servies/common.services";
import ToasterMessage from "../../../components/common/toaster_message";

import {getSettings, predictionJobsApi, resetPredictionJobs, updateSettings} from "../../../servies/settings.services";
import {CustomModal} from "../../../components/common/common_modals";

const {Column, HeaderCell, Cell} = Table;

export const PredictionJob = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [paramsData, setParamsData] = useState('');
    const [authData, setAuthData] = useState({});
    const [selectedRow, setSelectedRow] = useState({});
    const [predictionsData, setPredictionsData] = useState({});
    const [openSettingFormModal, setOpenSettingFormModal] = useState(false);
    const [isRestatingJob, setIsRestatingJob] = useState(false);

    const navigate = useNavigate();

    const Textarea = React.forwardRef((props, ref) => <Input {...props} as="textarea" ref={ref} />);


    const handelRestartJobs = () => {
        setIsRestatingJob(true);

        resetPredictionJobs(paramsData)
            .then((res) => {
                const data = res.data;
                if (data === null) {
                    ToasterMessage.error(res.error);
                    return;
                }
                ToasterMessage.success(res.message);
                setIsRestatingJob(false);

            })
            .catch((e) => {
                const errorCode = e.code;
                const errorMessage = e.message;
                ToasterMessage.error(errorMessage);
                setIsRestatingJob(false);
                console.log(errorCode, errorMessage);
            })
            .finally(() => {
                console.log('finally');
                setIsRestatingJob(false);
            })

    }


    useEffect(() => {
        const authSession = getAuthSession();
        if (authSession === null) {
            navigate('/admin/login');
            return;
        }

        const token = authSession.token;
        setAuthData(authSession);

        const params = `token=${token}&is_admin=true`;
        setParamsData(params);
        setIsLoading(true);

        predictionJobsApi(params.toString())
            .then(res => {
                const data = res.data;
                if (data === null) {
                    ToasterMessage.error(res.error);
                    return;
                }

                setPredictionsData(data);

            })
            .catch(reason => {
                ToasterMessage.error(reason.toString() + 'Settings');
                console.log(reason);
            })
            .finally(() => setIsLoading(false));

    }, [openSettingFormModal])

    return (
        <Fragment>
            {/*{updateSettingsDataModalRender()}*/}
            <Panel>
                <Button
                    appearance='primary'
                    Style='margin-bottom: 10px'
                    onClick={handelRestartJobs}
                    loading={isRestatingJob}
                >

                    Restart jobs
                </Button>
                <Table
                    height={'700'}
                    // width={'700'}
                    data={predictionsData}
                    loading={isLoading}
                    onRowClick={data => {
                        setSelectedRow(data);
                    }}
                >
                    <Column resizable={true} width={200} fullText={true}>
                        <HeaderCell>Store Name</HeaderCell>
                        <Cell dataKey='store_name'/>
                    </Column>
                    <Column width={230} resizable>
                        <HeaderCell>Shop URL</HeaderCell>

                        <Cell style={{ padding: '6px' }}>
                            {rowData => (
                                <Link to={{pathname:'/', search: `?${paramsData}&shop=${rowData.myshopify_domain}`,}} target={'_blank'}>
                                    {rowData.myshopify_domain}
                                </Link>
                            )}
                        </Cell>
                    </Column>
                    <Column resizable={true} width={200} fullText={true}>
                        <HeaderCell >Prediction Id</HeaderCell>
                        <Cell dataKey="prediction_id"/>
                    </Column>

                    <Column resizable={true} width={200} fullText={true}>
                        <HeaderCell>State</HeaderCell>
                        <Cell dataKey='state'/>
                    </Column>

                    <Column resizable={true} fullText={true}>
                        <HeaderCell>Job Id</HeaderCell>
                        <Cell dataKey="prediction_job_id"/>
                    </Column>



                    <Column resizable={true} width={200} fullText={true}>
                        <HeaderCell>Prediction Status</HeaderCell>
                        <Cell dataKey='prediction_status'/>
                    </Column>

                    <Column resizable={true} width={80} fullText={true}>
                        <HeaderCell>is Running</HeaderCell>
                        <Cell >
                            {rowData => (
                                <Toggle checked={rowData.is_running} />
                            )}
                        </Cell>
                    </Column>

                    <Column resizable={true} width={220} fullText={true}>
                        <HeaderCell>Created At</HeaderCell>
                        <Cell dataKey='created_at'/>
                    </Column>




                    {/*<Column fixed="right" resizable={true}>
                        <HeaderCell>Actions</HeaderCell>

                        <Cell style={{padding: '6px'}}>
                            {rowData => (
                                <div>
                                    <Button
                                        appearance="link"
                                        onClick={() => {
                                            setSelectedRow(rowData);
                                            setFormValue({name:rowData.name,value:rowData.value});
                                            setOpenSettingFormModal(true);
                                        }}
                                    >
                                        Edit
                                    </Button>
                                </div>
                            )}
                        </Cell>
                    </Column>*/}

                </Table>

            </Panel>
        </Fragment>
    )
}