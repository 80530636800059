import React, {Fragment, useCallback, useContext, useState} from 'react';
import {useEffect} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {getAuthSession, removeAuthSession} from "../../servies/common.services";
import {CustomNavBar} from "../../components/admin/CustomNavBar";
import Panel from "rsuite/Panel";
import {getAdminData} from "../../servies/admin.services";
import {Loader} from "rsuite";


export const AdminPanel = (props) => {
    const {search} = useLocation();
    const [authData, setAuthData] = useState({});
    const [activeKey, setActiveKey] = React.useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const {child} = props;


    useEffect(() => {
        setIsLoading(true);
        const authSession = getAuthSession();
        console.log('authSession', authSession)

        if (authSession === null) {
            navigate('/admin/login');
            return;
        }

        const token = authSession.token;
        const params = `token=${token}&is_admin=true`;
        getAdminData(params)
            .then((res) => {

                setIsLoading(false);
            })
            .catch((e) => {
                removeAuthSession();
                setIsLoading(false);
                navigate('/admin/login');

            })
            .finally(() => {
                setIsLoading(false);
            })


        setAuthData(authSession);

    }, [])


    return (
        <Fragment>
            {isLoading ? <Loader/> :
                <Panel>
                    <CustomNavBar activeKey={activeKey} onSelect={setActiveKey}/>
                    {child}
                </Panel>
            }
        </Fragment>

    )


}